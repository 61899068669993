.body-container {
  font-family: Verdana, sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
}
.header {
  background-image: url("./images/waves-bground-up.svg");
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 0;
  width: 100%;
  height: 40%;
}
h2 {
  text-align: center;
  color: white;
  font-size: 23px;
}
p {
  color: rgb(226, 158, 57);
  font-size: 18px;
  font-weight: bold;
}
.logo {
  width: 15%;
  position: relative;
  left: 120px;
  top: 20px;
}

span {
  color: #fff;
  font-weight: bold;
}

.waves-up {
  position: absolute;
  top: 0;
  width: 100%;
}

.emojis-control {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 6px;
  margin-top: 3rem;
}
.option {
  width: 50px;
  height: 50px;
  font-size: 28px;
  border: 3px solid #6610f2;
  border-radius: 15px;
  margin-left: 40px;
  margin-right: 40px;
  cursor: pointer;
}
.active {
  background-color: #6610f2;
}
li {
  list-style-type: none;
}

.form-control-b {
  width: 50%;
  height: 100vh;
  overflow: hidden;
}
.form-data {
  padding: 23% 0;
  text-align: center;
  flex-direction: column;
  position: relative;
  visibility: hidden;
    
}

.form-data > h4 {
  text-shadow: #33333d 0.1em 0.1em 0.2em;
  
}

strong {
  position: absolute;
  top: 300px;
  color: red;
  margin: 0 auto;
  font-size: 30px;
  text-align: center;
  z-index: 1;
  font-weight: bold;
}
h4 {
  z-index: 1;
  color: #ffffff;
  position: relative;
  top: -2rem;
  font-size: 16px;
  -webkit-text-stroke: 1px solid;
}

h3 {
  z-index: 1;
  color: #280a58;
  font-size: 15px;
  -webkit-text-stroke: 1px solid;
}
textarea {
  width: 80%;
  height: 15vh;
  margin: 0 auto;
  font-size: 17px;
  outline: none;
  resize: none;
  border-radius: 10px;
  padding: 1rem;
}
.count {
  position: relative;
  margin-left: 350px;
  font-size: 18px;
}
button {
  width: 250px;
  height: 60px;
  margin: 0 auto;
  margin-top: 1rem;
  background-color: #6610f2;
  border: none;
  border-radius: 10px;
  color: white;
  font-size: 20px;
  font-weight: bold;
  outline: none;
}
button:hover {
  background-color: #844cdf;
  opacity: 2;
  transition: 20ms;
}

h6 {
  color: #6610f2;
  position: absolute;
  top: 340px;
  margin: 0 auto;
  font-size: 30px;
  text-align: center;
  z-index: 1;
  font-weight: bold;
}

strong {
  position: absolute;
  top: 340px;
  color: red;
  margin: 0 auto;
  font-size: 30px;
  text-align: center;
  z-index: 1;
  font-weight: bold;
}

@media screen and (max-width: 1545px) {
  .form-control-b {
    width: 40%;
  }
  .option {
    margin-left: 30px;
    margin-right: 30px;
  }
  strong {
    font-size: 25px;
  }
  h4 {
    top: -2rem;
  }
}
@media screen and (max-width: 1400px) {
  h4 {
    top: -1rem;
  }
  h2 {
    font-size: 20px;
  }
}

@media screen and (max-width: 1200px) {
  h2 {
    font-size: 25px;
  }
  .option {
    margin-left: 20px;
    margin-right: 20px;
  }
  .form-control-b {
    width: 70%;
  }
  .logo {
    width: 30%;
    padding-bottom: 40px;
  }
  h6 {
    font-size: 20px;
  }
}
@media screen and (max-width: 1110px) {
  h4 {
    top: 1rem;
  }
}
@media screen and (max-width: 900px) {
  h4 {
    top: 1rem;
  }
  h2 {
    font-size: 20px;
  }
}
@media screen and (max-width: 820px) {
  h2 {
    font-size: 25px;
  }
  h4 {
    top: -0.2rem;
    font-size: 15px;
  }
  h3 {
    padding-bottom: 1rem;
    font-size: 15px;
  }
  .count {
    margin-left: 250px;
  }
  .form-control-b {
    width: 80%;
  }
  h6 {
    font-size: 15px;
  }
}
@media screen and (max-width: 710px) {
  .form-control-b {
    width: 90%;
  }
  strong {
    font-size: 18px;
  }
  p {
    font-size: 15px;
  }
}
@media screen and (max-width: 750px) {
  h2 {
    font-size: 18px;
  }
}
@media screen and (max-width: 625px) {
  h2 {
    font-size: 17px;
  }

  .count {
    margin-left: 250px;
  }
  .logo {
    width: 28%;
    left: 70px;
    top: 15px;
  }
  .form-control-b {
    width: 90%;
  }
  h3 {
    font-size: 12px;
  }
  h4 {
    top: 1rem;
    font-size: 12px;
  }
  button {
    width: 180px;
    height: 40px;
  }
}
@media screen and (max-width: 490px) {
  h4 {
    top: 1.5rem;
    font-size: 12px;
  }
  h2 {
    font-size: 15px;
  }
  strong{
    font-size: 15px;
  }
}
@media screen and (max-width: 468px) {
  .emojis-control {
    margin-bottom: 1rem;
  }
  p {
    font-size: 10px;
  }
  .count {
    margin-left: 150px;
  }

  h4 {
    top: 1.8rem;
  }
}

@media screen and (max-width: 440px) {
  .logo {
    width: 130px;
    left: 60px;
    top: 15px;
  }
  h2 {
    margin: 1px;
  }
  h4 {
    font-size: 12px;
  }
  .emojis-control {
    margin-bottom: 1rem;
  }
}

@media screen and (max-width: 416px) {
  .header {
    width: 105%;
  }
  .logo {
    width: 30%;
    left: 60px;
    top: 20px;
  }
  h2 {
    font-size: 13px;
  }
  h4 {
    top: 2rem;
    font-size: 12px;
  }
  .emojis-control {
    margin-bottom: 1rem;
  }
  .option {
    width: 40px;
    height: 40px;
    font-size: 22px;
    border: 3px solid #6610f2;
    border-radius: 15px;
    margin-left: 10px;
    margin-right: 10px;
  }
}

@media screen and (max-width: 380px) {
  .logo {
    width: 30%;
  }
  h4 {
    top: 2.1rem;
    font-size: 10px;
  }
  h2 {
    font-size: 12px;
    margin: 25px;
  }
  h3 {
    font-size: 10px;
    margin: 10px;
  }
  .form-control-b {
    width: 100%;
  }
  .form-data {
    padding: 40% 0;
  }
}
