.Container{
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  background-color: #844cdf;
}


h1{
  padding: 50px;
  text-align: center;
  color: aliceblue;
  font-size: 35px;

}
